import { ReactComponent as Crane } from "./crane.svg";
import { ReactComponent as BG } from "./bg.svg";
import { ReactComponent as Doodle } from "./doodle.svg";
export default function App() {
  return (
    <>
      <div className="lg:hidden bg-[#FFC810] overflow-hidden min-h-screen">
        <BG className="absolute left-0 z-[-1] w-full h-full" />
        <div className="flex flex-col justify-center h-screen">
          <div className="text-center">
            <img
              src="/giraffe.png"
              alt="Twiga Foods Limited"
              className="w-[200px] m-auto"
            />
          </div>
          <div className="flex justify-center">
            <Message />
          </div>
        </div>
      </div>
      <div className="hidden lg:block">
        <BG className="absolute right-0 z-0" />
        <Doodle className="absolute left-0 right-0 z-0 w-full m-auto" />
        <img
          src="/giraffe.png"
          alt="Twiga Foods Limited"
          className="w-[450px] absolute left-0 right-0 bottom-[40px] m-auto"
        />
        <div className="grid items-center h-screen grid-cols-1">
          <div className="grid grid-cols-2 gap-3">
            <div className="flex">
              <Crane className="absolute bottom-[40px] left-[80px]" />
            </div>
            <div className="z-40 flex justify-center">
              <Message />
            </div>
          </div>

          <footer className="absolute bottom-0 w-full py-3 bg-[#1C1C53]">
            <div className="flex justify-between px-3 text-white">
              <div>
                <a href="mailto:info@twiga.com">info@twiga.com</a>
              </div>
              <div className="text-center">
                <img
                  src="/twiga_logo.png"
                  alt="Twiga Foods Limited"
                  width="80px"
                  height="auto"
                  className="m-auto"
                />
              </div>
              <div>+254 (0)709 258 00</div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

function Message() {
  return (
    <div className="bg-white shadow-lg max-w-[450px] mx-4 rounded-md overflow-hidden">
      <h3 className="bg-[#DCE1EE] text-center  py-1 lg:py-2 text-[#002D65] font-medium text-xl">
        Website Under Construction
      </h3>
      <div className="px-6 pb-8">
        <h4 className="text-[#002D65] text-2xl text-center font-extrabold py-4">
          We'll be right back
        </h4>
        <p className="text-sm text-center">
          We’re enhancing your experience on our website to serve you better.
          Stay tuned as we make improvements to deliver an even more seamless
          and enjoyable browsing experience.{" "}
        </p>

        <div className="flex justify-center mt-2 text-center lg:mt-4">
          <a
            href="https://www.twiga.shop/"
            target="_blank"
            rel="noreferrer"
            className="bg-[#1C1C53] text-white uppercase py-1 px-2 lg:px-4  text-xs lg:text-base rounded-md font-medium"
          >
            Vendor signup
          </a>
          <a
            href="mailto:sokosolution@twiga.com"
            className="text-[#1C1C53] ml-6 py-1 uppercase px-2 lg:px-4 text-xs lg:text-base rounded-md bg-[#FFC810] font-medium"
          >
            Supplier signup
          </a>
        </div>
        <div className="flex justify-between px-5 pt-4 lg:pt-8">
          <a
            href="https://twitter.com/TwigaFoods"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <img
                src="/socials/x.png"
                alt="Twiga on twitter"
                width="30px"
                height="auto"
              />
            </div>
          </a>
          <a
            href="https://ke.linkedin.com/company/twiga-foods"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <img
                src="/socials/linkedin.png"
                alt="Twiga on Linkedin"
                width="30px"
                height="auto"
              />
            </div>
          </a>
          <a
            href="https://web.facebook.com/twigafoods/"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <img
                src="/socials/fb.png"
                alt="Twiga on facebook"
                width="30px"
                height="auto"
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/twigafoods/"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <img
                src="/socials/instagram.png"
                alt="Twiga on instagram"
                width="30px"
                height="auto"
              />
            </div>
          </a>
        </div>
        <p className="text-[#1C1C53] text-center text-xs pt-3 lg:pt-6">
          &copy; {new Date().getFullYear()} Twiga Foods Limited. All rights
          reserved
        </p>
      </div>
    </div>
  );
}
